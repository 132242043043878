import { Grid, Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from "@material-ui/core/styles";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import moment from 'moment';
import React, { Component } from 'react';
import AutoCompleteMany from '../../components/autoCompleteMany/autoCompleteMany';
import EmptyList from '../../components/emptyList/emptyList';
import FormField from '../../components/formField/formField';
import GenericButton from '../../components/genericButton/genericButton';
import InputSelect from '../../components/inputselect/inputselect';
import Page from '../../components/page/page';
import Resumo from '../atendimento/resumo';
import API from './../../comm/API';
import SimpleModal from './../../components/modal/modal';
import Table from './../../components/table/table';
import './solicitacoesDN.scss';

export default class SolicitacoesDN extends Component {

    state = {
        listAtendimentos: [],
        filtro: '', from: 0, to: 49, totalAtendimentos: 0, total: 0, dateOption: {}, solicitacaoStatusList: this.getSolicitacaoStatus(),
        dateFilter: {}, dataInicio: new Date(moment().subtract(12, "M").format('MMMM D, YYYY')), dataFim: new Date(moment()),
        atendimento: null, indicadores: null, originalSolicitacaoStatus: null, hasFilter: false, previsaoReceita: null,
        isMetaGoverno: '' , emptyList: 'noItens', parecerDN: { atendimentosSelected: [], status: { id: 0 }, nota: '' },
        newStatus: null
    };

    errorDatas = '';

    titles = [
        { id: '0', canOrder: true, label: 'Id' },
        { id: '1', canOrder: true, label: 'Nº' },
        { id: '2', canOrder: true, label: 'Título' },
        { id: '3', canOrder: true, label: 'Cliente' },
        { id: '4', canOrder: true, label: 'Proposta' },
        { id: '5', canOrder: false, label: 'Produto Nacional' },
        { id: '6', canOrder: true, label: 'DR' },
        { id: '7', canOrder: true, label: 'Unidade' },
        { id: '8', canOrder: false, label: 'Status' }];
    
    statusParecerOptions = [
        { id: 1, key: 'conforme', label: 'Conforme' },
        { id: 2, key: 'naoconforme', label: "Não Conforme" }
    ];

    atendimentoList = [];
    parametros = [];
    canChangeStatus = false;

    isDN = true;
    isDR = false;
    isUO = false;

    modalFiltro = false;

    isAtendimentoBP = false;
    isAtendimentoIA = false;
    isAtendimentoIPEA = false;
    isAtendimentoLEAN28 = false;
    isAtendimentoLEAN56 = false;
    isAtendimentoMENTORIA = false;
    isAtendimentoLEAN = false;
    isAtendimentoIA180 = false;
    isAtendimentoDIGITAL = false;
    isAtendimentoBrasilMaisLEAN = false;
    isMetrologia = false;
    isInovacao = false;
    isPronto = false;
    isNegociacao = false;
    isAceito = false;
    isExecucao = false;
    isConcluido = false;
    isArquivado = false;
    indicadores = null;
    atendimentoTurma = null;
    turma = null;
    isModuloNacional = false;

    filter = {};
    filterCp = {};

    dateOption = 3;
    dateOptions = [
        { id: 0, label: 'Últimos 30 dias' },
        { id: 1, label: 'Últimos 6 meses' },
        { id: 2, label: 'Último ano' },
        { id: 3, label: 'Últimos 10 anos' },
    ];

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    }

    getSolicitacaoStatus() {
        return [
            { chave: 'analise', descricao: 'Em Análise', checked: false, ids: [1, 4, 7, 10, 13, 16, 19], group: 'grey' },
            { chave: 'conforme', descricao: 'Conforme', checked: false, ids: [2, 5, 8, 11, 14, 17, 20] },
            { chave: 'naoconforme', descricao: 'Não Conforme', checked: false, ids: [3, 6, 9, 12, 15, 18, 21] }]
    }

    async componentDidMount() {
        this.filter = await this.getBasicFilter();
        this.filterCp = this.getBasicFilter();
        this.getParametros();
        this.getAtendimentos();
    }

    openModal = async (e) => {
        if (e != null && e !== '') {
            e.icon = e.label === 'Conforme' ? 'conforme' : 'naoconforme';
            let parecerDN = this.state.parecerDN;
            parecerDN.status.id = e.id;
            this.setState({ parecerDN: parecerDN, newStatus: e });
        }
    };

    getType = () => {
        if (this.state.newStatus.label === 'Conforme') return 'success';
        return 'warning';
    };

    getParametros = async () => {
        this.parametros = (await API.get('rest/gestao/ParametroUnidade/produtos')).data;
        this.idProdutoBrasilProdutivo = parseInt(this.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO);
        this.idProdutoIndustriaMaisAvancada = parseInt(this.parametros.ID_INDUSTRIA_MAIS_AVANCADA);
        this.idProdutoBrasilProdutivoIPEA = parseInt(this.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_IPEA);
        this.idProdutoBrasilProdutivoIE = parseInt(this.parametros.ID_EFICIENCIA_ENERGETICA);
        this.idProdutoBrasilProdutivoLEAN28 = parseInt(this.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_LEAN_28);
        this.idProdutoBrasilProdutivoLEAN56 = parseInt(this.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_LEAN_56);
        this.idProdutoBrasilProdutivoMENTORIA = parseInt(this.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_MENTORIA);
        this.idConsultoriaManufatura180 = parseInt(this.parametros.ID_CONSULTORIA_MANUFATURA_180);
        this.idMentoriaDigital = parseInt(this.parametros.ID_MENTORIA_DIGITAL);
        this.idProdutoBrasilMaisMentoriaLEAN = parseInt(this.parametros.ID_PRODUTO_BRASIL_MAIS_MENTORIA_LEAN);
    }

    getFiltro() {
        let filtro = '?produtoNacional.isBaseNacional=true&atendimento.idsolicitacaostatus<>null';
        if (this.filter.emAnalise) filtro += '&solicitacaoStatus.descricao=\'Em Análise\'';
        if (this.filter.conforme) filtro += '&solicitacaoStatus.descricao=\'Conforme\'';
        if (this.filter.naoConforme) filtro += '&solicitacaoStatus.descricao=\'Não Conforme\'';
    
        if (this.filter.produtoNacionalList.length > 0)
            for (let i = 0; i < this.filter.produtoNacionalList.length; i++) filtro += '&produtoNacional.id=' + this.filter.produtoNacionalList[i].produto.id;
        if (this.filter.unidadeList.length > 0)
            for (let i = 0; i < this.filter.unidadeList.length; i++) filtro += '&unidade.id=' + this.filter.unidadeList[i].unidade.id;
        else if (this.filter.regionalList.length > 0)
            for (let i = 0; i < this.filter.regionalList.length; i++) filtro += '&unidadePai.id=' + this.filter.regionalList[i].unidade.id;
        if (this.state.filtro) filtro += `&buscaLivreAtendimento=${this.state.filtro} `;
        filtro += '&atendimento.dataEmissao=between('+ this.formatData(this.filter.dataInicio) + 'T00:00:00,' + this.formatData(this.filter.dataFim) + 'T23:59:59) ';
        filtro += `&from=${this.state.from} `
        filtro += '&ordenarPor=id desc';
        return filtro;
    }

    formatData(date) {
        let dataList = '';
        if (date != null) {
            if (typeof (date) !== 'string') {
                dataList = date.toLocaleDateString('pt-BR').split('/');
                return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
            } else {
                if (date.length > 10) {
                    dataList = date.split('T');
                    return dataList[0];
                } else {
                    return date;
                }
            }
        }
    }

    downloadRelatorioIndicadores = () => {
        this.showLoading();

        try {
            let path = `https://api.sgt.isitics.com/relatorio/exportar`;
            window.open(path);
            this.closeLoading();
            
        } catch (error) {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro ao gerar relatório. Favor, entrar em contato com o suporte.', 'error');
        }

    };

    changeInput = (e) => {
        if (e != null && e.target != null) {
            if (e.target.type === 'date' && e.target.value) {
                this.filter[e.target.id] = e.target.value;
                this.dateOption = undefined;
                if (this.formatData(this.filter.dataInicio) > this.formatData(this.filter.dataFim)) this.errorDatas = 'Intervalo de datas inválido.';
                else this.errorDatas = '';
            } 
            this.setState(this.state);
        } else if (e && e.id != null) {
            this.dateOption = e.id;
            this.filter.dataFim = new Date();
            this.filter.dataInicio = new Date();
            if (e.id === 0) {
                this.filter.dataInicio.setDate(this.filter.dataInicio.getDate() - 30);
            } else if (e.id === 1) {
                this.filter.dataInicio.setMonth(this.filter.dataInicio.getMonth() - 6);
            } else if (e.id === 2) {
                this.filter.dataInicio.setYear(this.filter.dataInicio.getFullYear() - 1);
            } else {
                this.filter.dataInicio.setYear(this.filter.dataInicio.getFullYear() - 10);
            }
            this.setState(this.state);
        }
    }

    getAtendimentos = async () => {
        this.showLoading();
        try {
            let countListAtendimentos = await API.get(`/rest/gestao/Atendimento/countAtendimentos${this.getFiltro()}`);
            API.get(`/rest/gestao/Atendimento/find${this.getFiltro()}`).then(res => {
                let listAtendimentos = res.data;
                this.setState({ listAtendimentos: this.createLine(listAtendimentos), totalAtendimentos: countListAtendimentos.data.count, total: Math.ceil(countListAtendimentos.data.count / 50) });
                this.setState({ emptyList: countListAtendimentos.data.count === 0 ? 'noResults' : '' });
                this.closeLoading();
            }).catch(error => {
                console.log(error)
                this.closeLoading();
                this.showToast('Erro ao buscar os atendimentos. Por favor, entre em contato com o suporte.', 'error')
            });
        } catch (error) {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro ao exibir a lista de atendimentos. Por favor entre em contato com o suporte', 'error');
        }
    }

    openAtendimento = async (id) => {
        this.showLoading();
        this.getTurma(id).then( _ => {
            API.get(`/rest/gestao/Atendimento/${id}`).then(async res => {
                let atendimento = res.data;
                this.isAtendimentoBP = (atendimento.produtoRegional.produtoNacional.id === this.idProdutoBrasilProdutivo);
                this.isAtendimentoIA = (atendimento.produtoRegional.produtoNacional.id === this.idProdutoIndustriaMaisAvancada);
                this.isAtendimentoIPEA = (atendimento.produtoRegional.produtoNacional.id === this.idProdutoBrasilProdutivoIPEA);
                this.isAtendimentoIE = (atendimento.produtoRegional.produtoNacional.id === this.idProdutoBrasilProdutivoIE)
                this.isAtendimentoLEAN28 = (atendimento.produtoRegional.produtoNacional.id === this.idProdutoBrasilProdutivoLEAN28);
                this.isAtendimentoLEAN56 = (atendimento.produtoRegional.produtoNacional.id === this.idProdutoBrasilProdutivoLEAN56);
                this.isAtendimentoMENTORIA = (atendimento.produtoRegional.produtoNacional.id === this.idProdutoBrasilProdutivoMENTORIA);
                this.isAtendimentoLEAN = this.isAtendimentoLEAN28 || this.isAtendimentoLEAN56 || this.isAtendimentoMENTORIA;
                this.isAtendimentoIA180 = (atendimento.produtoRegional.produtoNacional.id === this.idConsultoriaManufatura180);
                this.isAtendimentoDIGITAL = (atendimento.produtoRegional.produtoNacional.id === this.idMentoriaDigital);
                this.isAtendimentoBrasilMaisLEAN = (atendimento.produtoRegional.produtoNacional.id === this.idProdutoBrasilMaisMentoriaLEAN);
                this.isModuloNacional = atendimento.produtoRegional.produtoNacional.isBaseNacional && !JSON.stringify(this.parametros).includes(atendimento.produtoRegional.produtoNacional.id);
                await this.setState({ atendimento: atendimento, originalSolicitacaoStatus: atendimento.solicitacaoStatus });
                if (!this.isAtendimentoBrasilMaisLEAN) await this.getIndicadores();
                await this.getStatus();
                this.closeLoading();
            }).catch(error => {
                this.closeLoading();
            });
        }).catch( _ => {
            this.closeLoading();
        }) 
    }

    getTurma = async (idAtendimento) => {
        return new Promise((resolve, reject) => {
            API.get(`/rest/gestao/${this.isAtendimentoDIGITAL ? 'TurmaMentoriaDigital' : 'Turma'}/findByAtendimento?idAtendimento=${idAtendimento}`)
            .then(async response => {
                const { data } = response;
                if (data != null && (data.type === 'turma' || data.type === 'turmaMentoriaDigital')) {
                    this.turma = data;
                    const { atendimentoTurmaList } = this.turma;
                    this.atendimentoTurma = atendimentoTurmaList.find(e => e.atendimento.id = idAtendimento);
                } else if (data != null && data.atendimento != null) {
                    this.turma = data.turma;
                    this.atendimentoTurma = data;
                } else {
                    this.turma = null;
                    this.atendimentoTurma = null;
                }
                await this.setState(this.state);
                resolve();
            }).catch(error => {
                console.log(error);
                this.showToast('Ocorreu um erro ao tentar obter os dados da turma. Por favor, entre em contato com o suporte.', 'error');
                reject();
            });
        });
    }

    getIndicadores = () => {
        API.get('/rest/gestao/Indicadores?atendimento.id=' + this.state.atendimento.id).then(res => {
            if (res.data.length > 0) {
                this.setState({ indicadores: res.data });
                this.closeLoading();
            } else {
                this.closeLoading();
            }
        }).catch(error => {
            this.closeLoading();
        });
    }

    createLine(atendList) {      
        let atendimentosFiltrados = [];
        atendList.forEach(atend => {
            let atd = [];
            atd.push(atend[0]);
            atd.push(atend[1]);
            atd.push(atend[5]);
            atd.push(atend[2]);

            atd.push(atend[16]);
            atd.push(atend[17]);
            atd.push(atend[18]);
            atd.push(atend[4]);
            atd.push(this.getColorStatusSolicitacao(atend[19], atend[21]));
            atd.push(atend[19]);
            atd.push(atend[20]);
            atendimentosFiltrados.push(atd);
        });
        return atendimentosFiltrados;
    }

    changeSolicitacaoStatus(checked, position) {
        let list = this.state.solicitacaoStatusList;
        list[position].checked = checked;
        this.setState({ solicitacaoStatus: list });
    }

    createTemplateSolicitacao(solicitacaoStatus) {
        return (
            <div className={"change-status"}>
                <div>
                   <label className={solicitacaoStatus.key + ' solicitacaoStatus'}>{solicitacaoStatus.label}</label>
                </div>
            </div>
        )
    };

    getColorStatusSolicitacao(solicitacaoStatus, ultimaAtualizacao) {
        if (solicitacaoStatus) {
            if (this.state.solicitacaoStatusList[0].descricao.includes(solicitacaoStatus)) {
                return (
                    <div className={"tooltip-solicitacao"}>
                        <span className={'tooltip-info'}>
                            {!isNaN(Date.parse(ultimaAtualizacao)) ? `Última atualização: ${this.formatDataTimestamp(ultimaAtualizacao, true)}` : ultimaAtualizacao}
                        </span>
                        <span className={'em-analise'}>{solicitacaoStatus}</span>
                    </div>
                )
            }
            if (this.state.solicitacaoStatusList[1].descricao.includes(solicitacaoStatus)) {
                return <span className='conforme'>{solicitacaoStatus}</span>;
            }
            if (this.state.solicitacaoStatusList[2].descricao.includes(solicitacaoStatus)) {
                return <span className='nao-conforme'>{solicitacaoStatus}</span>;
            }
        }
    }

    changeBuscaLivre = (e) => {
        this.setState({ filtro: e.target.value });
    }

    changeRange = (e) => {
        if (e === 0) {
            this.setState({ from: 0, to: 49 }, this.getAtendimentos);
        } else if (e === 1) {
            this.setState({ from: this.state.from + 50, to: this.state.to + 50 }, this.getAtendimentos);
        } else if (e === -1) {
            this.setState({ from: this.state.from - 50, to: this.state.to - 50 }, this.getAtendimentos);
        } else if (e === this.state.totalAtendimentos) {
            let diff = this.state.totalAtendimentos - ((this.state.total - 1) * 50); 
            this.setState({ from: this.state.totalAtendimentos - diff, to: (((this.state.total - 1) * 50) - 1) + 50 }, this.getAtendimentos);
        }
        let parecerDN = this.state.parecerDN;
        parecerDN.atendimentosSelected = [];
        this.setState({ parecerDN: parecerDN });
        window.scrollTo(0, 0);
    }

    formatDataTimestamp(timestamp, hasHour) {
        var data = new Date(timestamp).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
        let dataSplit = data.split(' ');
        let dataList = dataSplit[0].split('/');
        let hour = '';
        if (hasHour) hour = ' às ' + dataSplit[1].split(':').slice(0, 2).join(':')
        return dataList[0] + '/' + dataList[1] + '/' + dataList[2] + hour;
    }

    getStatus = () => {
        this.isMetrologia = this.state.atendimento.produtoRegional.produtoNacional.produtoCategoria.produtoLinha.id === 3;
        this.isInovacao = this.state.atendimento.produtoRegional.produtoNacional.produtoCategoria.produtoLinha.id === 2;
        this.isSTE = this.state.atendimento.produtoRegional.produtoNacional.produtoCategoria.produtoLinha.id === 4;
        this.isArquivado = this.state.atendimento.atendimentoStatus.chave === 'arquivado';
        this.isPronto = this.state.atendimento.atendimentoStatus.chave === 'pronto';
        this.isNegociacao = this.state.atendimento.atendimentoStatus.chave === 'negociacao';
        this.isAceito = this.state.atendimento.atendimentoStatus.chave === 'aceito';
        this.isExecucao = this.state.atendimento.atendimentoStatus.chave === 'emexecucao';
        this.isConcluido = this.state.atendimento.atendimentoStatus.chave === 'concluido';
        this.isCancelado = this.atendimento.atendimentoStatus.chave === 'cancelado';
    }

    updateParecerDNAtendimentos = async () => {
        let parecerDN = this.state.parecerDN;
        try {
            this.showLoading();
            await API.post('/rest/gestao/Atendimento/updateParecerDNAtendimentos', parecerDN);
            this.getAtendimentos();
            this.closeLoading();
            this.showToast('Atendimento(s) atualizado(s) com sucesso!', 'success');
            this.clearChanges();
            parecerDN.atendimentosSelected = [];
            this.setState({ parecerDN: parecerDN });
        } catch (error) {
            this.closeLoading();
            this.showToast('Houve um problema ao tentar atualizar o(s) atendimento(s). Por favor, entre em contato com o suporte.', 'error');
        }
    }

    editarSolicitacaoStatus = (e) => {
        if (e != null) {
            let atendimento = this.state.atendimento;
            atendimento.solicitacaoStatus = e;
            this.setState({ atendimento: atendimento });
        }
    }

    getBasicFilter = () => {
        let filter = {
            dataInicio: new Date(),
            dataFim: new Date(),
            produtoNacionalList: [],
            regionalList: [],
            unidadeList: [],
            emAnalise: true,
            conforme: false,
            naoConforme: false,
        };
        filter.dataInicio.setYear(filter.dataInicio.getFullYear() - 10);
        this.errorDatas = '';
        return filter;
    }

    changeModal = (modal, status) => {
        this[modal] = status;
        if ((modal === 'modalFase') && status) {
            this.atendimentoFase = { fase: null, dataFinal: null, quantidadeDeHoras: 0 }
        }
        this.setState(this.state);
    }

    changeModalShowToast = (modal, status, msg) => {
        this[modal] = status;
        if ((modal === 'modalFase') && status) {
            this.atendimentoFase = { fase: null, dataFinal: null, quantidadeDeHoras: 0 }
        }
        this.setState(this.state);
        this.showToast(msg, 'success');
    };

    changeFiltro = (flag) => {
        this.getAtendimentos();
        this.changeModal('modalFiltro', false);
        if (flag) this.filterCp = Object.assign({}, this.filter);
        this.setState({ hasFilter: flag });
    }

    createTemplateUnidade(unidade) {
        return (
            <div className={"auto-complete-options"}>
                <div>
                    <label>{unidade.abreviacao}</label>
                </div>
                <div>
                    <p>{unidade.municipio.descricao} - {unidade.municipio.unidadeFederativa.sigla}</p>
                </div>
            </div>
        );
    }

    createTemplateProdutoNacional(produto) {
        return (
            <div className={"auto-complete-options"}>
                <div>
                    <label>{produto.descricao}</label>
                </div>
                <div>
                    <p>{produto.produtoCategoria.descricao} - {produto.produtoCategoria.produtoLinha.descricao}</p>
                    <p>Produto de base nacional: {produto.isBaseNacional ? 'SIM' : 'NÃO'}</p>
                </div>
            </div>
        );
    }

    changeUnidade = (e) => {
        if (e) {
            this.filter.unidadeList = e;
            this.changeInputFlag = true;
            this.setState(this.state);
        }
    }

    changeProdutoNacional = (produtos) => {
        if (produtos) {
            this.filter.produtoNacionalList = produtos;
            this.changeInputFlag = true;
            this.setState(this.state);
        }
    }

    changeRegional = (e) => {
        if (e) {
            this.filter.regionalList = e;
            let iDs = this.filter.regionalList.map(item => item.unidade.id);
            this.filter.unidadeList = this.filter.unidadeList.filter(item => iDs.includes(item.unidade.unidadePai.id));
            this.changeInputFlag = true;
            if (this.filter.regionalList.length === 0) this.filter.unidadeList = [];
            this.setState(this.state);
        }
    }

    clearChanges = () => {
        let parecerDN = this.state.parecerDN;
        parecerDN.status.id = 0;
        parecerDN.nota = '';
        this.setState({ parecerDN: parecerDN });
    }

    clearAtendimento = () => {
        this.setState({ atendimento: null });
    }

    refreshAtendimento = (atendimento) => {
        this.setState({ atendimento });
    }

    render() {
        let regionaisPath = '';
        if (this.filter && this.filter.regionalList && this.filter.regionalList.length > 0) {
            for (let i = 0; i < this.filter.regionalList.length; i++) regionaisPath += 'unidadePai.id=' + this.filter.regionalList[i].unidade.id + '&';
        }
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
          })(Tooltip);
          
        return (
            <Page icon={'flag.svg'} label={'Monitoramento DN'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'gerenciar-atendimentos solicitacoes-dn'}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Grid container>
                                    { this.state.atendimento == null &&
                                        <Grid item xs={5}>
                                            <div className={'grid-right-button'}>
                                                <FormField type={'text'}
                                                    changeValue={this.changeBuscaLivre} pressEnter={this.getAtendimentos} placeholder={'Busque por ID, Nº, proposta, título, cliente, apl ou setor'}
                                                    title={'Busque por ID, Nº, proposta, título, cliente, apl ou setor'}
                                                    id={'busca'} button={<GenericButton color={'darkBlue'} subClass={'icon-button'}
                                                    icon={process.env.PUBLIC_URL + 'search.svg'} click={this.getAtendimentos} />} 
                                                    inputValue={this.state.filtro}/>
                                            </div> 
                                        </Grid> }
                                    { this.state.atendimento == null && 
                                    
                                    <Grid item xs={7}  style={{ display: 'flex', height: '30px' }}>
                                        <Grid container spacing={2}>
                                            <Grid item >
                                                <GenericButton color={'lightBlue'} label={'Busca avançada'} subClass={'basic-button busca-avancada'}
                                                    icon={process.env.PUBLIC_URL + 'filter.svg'} click={() => this.changeModal('modalFiltro', true)} />
                                                { this.state.hasFilter ?
                                                    <GenericButton color={'white'} subClass={'icon-button'} click={() => { this.filter = this.getBasicFilter(); this.filterCp = this.getBasicFilter(); 
                                                        this.changeFiltro(false); }} icon={'close.svg'} />
                                                        : null }
                                            </Grid>
                                            <Grid item  >
                                            <GenericButton color={'darkBlue'} label={'Relatório Indicadores'} subClass={'basic-button '}
                                                     click={() => this.downloadRelatorioIndicadores()} />
                                            </Grid>
                                        </Grid>
                                    </Grid> }
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                
                                { this.state.atendimento == null && 
                                    <InputSelect
                                        suggestions={this.statusParecerOptions}
                                        label={'Parecer DN'}
                                        itemKey={'id'}
                                        itemLabel={'label'}
                                        id={'statusParecer'}
                                        getInput={this.openModal}
                                        itemTemplate={this.createTemplateSolicitacao}
                                        initialSelectedItem={{ id: this.state.parecerDN.status.id }}
                                        disabled={this.state.parecerDN.atendimentosSelected.length === 0} />
                                 }
                                
                            </Grid>
                        </Grid>
                        { this.state.parecerDN.status.id !== 0 &&
                            <SimpleModal
                                isOpen={this.state.parecerDN.status.id !== 0}
                                handleClose={this.clearChanges}
                                type={`${this.getType()}`}
                                width={'500px'}>
                                <div className={'modal'}>
                                    <Grid container className={'modal-change-status'}>
                                        <p>Você confirma a atualização do parecer para</p>
                                        <label className={`${this.state.newStatus.icon}`}> {this.state.newStatus.label}? </label>
                                        <br></br>
                                        {this.state.newStatus !== null ?
                                            <Grid item xs={12} className={'field-note'}>
                                                <p>Adicionar observação (opcional)</p>
                                                <FormField
                                                    type={'textarea'}
                                                    placeholder={'Escreva uma nota sobre esse parecer'}
                                                    changeValue={(e) => {
                                                        let parecerDN = this.state.parecerDN;
                                                        parecerDN.nota = e.target.value;
                                                        this.setState({ parecerDN: parecerDN });
                                                    }}
                                                    disabled={false} 
                                                    inputValue={this.state.parecerDN.nota} maxLength={'500'} />
                                            </Grid>
                                        : null}
                                        <Grid item xs={12} className={'rodape'}>
                                            <GenericButton
                                                color={'darkGrey-outline'}
                                                label={'Cancelar'}
                                                subClass={'basic-button'}
                                                click={this.clearChanges} />

                                            <GenericButton color={'darkBlue'}
                                                label={'Confirmar'}
                                                subClass={'basic-button'}
                                                click={this.updateParecerDNAtendimentos} />
                                        </Grid>
                                    </Grid>
                                </div>
                            </SimpleModal>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={'apropriar'}>
                            { this.state.atendimento == null && 
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Table lines={this.state.listAtendimentos} titles={this.titles} pagination={false}
                                                action={this.openAtendimento} actionParamer={0} initialOrder={''}
                                                checkBoxCondition={[9, 'Em Análise']} selected={this.state.parecerDN.atendimentosSelected}
                                                handleSelected={(e) => {
                                                    let parecerDN = this.state.parecerDN;
                                                    parecerDN.atendimentosSelected = e;
                                                    this.setState({ parecerDN: parecerDN }); 
                                                }}/>
                                            { this.state.listAtendimentos.length > 0 ?
                                                <div className={'pagination'}>
                                                    <p>{(this.state.from + 1).toLocaleString('pt-BR')}-{(this.state.from + this.state.listAtendimentos.length).toLocaleString('pt-BR')} de {this.state.totalAtendimentos.toLocaleString('pt-BR')}</p>
                                                    <IconButton onClick={() => this.changeRange(0)} disabled={this.state.from <= 0}>
                                                        <FirstPageIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => this.changeRange(-1)} disabled={this.state.from <= 0}>
                                                        <KeyboardArrowLeft />
                                                    </IconButton>
                                                    <IconButton onClick={() => this.changeRange(1)} disabled={!(this.state.totalAtendimentos >= 1 && (this.state.to <= this.state.totalAtendimentos))}>
                                                        <KeyboardArrowRight />
                                                    </IconButton>
                                                    <IconButton onClick={() => this.changeRange(this.state.totalAtendimentos)} disabled={!(this.state.totalAtendimentos >= 1 && (this.state.to <= this.state.totalAtendimentos))}>
                                                        <LastPageIcon />
                                                    </IconButton>
                                                </div> : null }
                                            <EmptyList type={this.state.emptyList}/>    
                                        </Grid>
                                    </Grid>
                                </Grid> }
                            { this.state.atendimento !== null && 
                                <Grid container spacing={3} className={'atendimento'}>
                                    <Resumo
                                        canChangeStatus={this.canChangeStatus}
                                        atendimento={this.state.atendimento}
                                        usuario={this.props.usuarioConectado}
                                        indicadores={this.state.indicadores}
                                        isDN={this.isDN}
                                        isAceito={this.isAceito}
                                        isNegociacao={this.isNegociacao}
                                        isPronto={this.isPronto}
                                        isExecucao={this.isExecucao}
                                        isConcluido={this.isConcluido}
                                        isArquivado={this.isArquivado}
                                        isCancelado={this.isCancelado}
                                        isAtendimentoBP={this.isAtendimentoBP}
                                        isAtendimentoIA={this.isAtendimentoIA}
                                        isAtendimentoIA180={this.isAtendimentoIA180}
                                        isAtendimentoIE={this.isAtendimentoIE}
                                        isAtendimentoIPEA={this.isAtendimentoIPEA}
                                        isAtendimentoLEAN={this.isAtendimentoLEAN}
                                        isAtendimentoLEAN28={this.isAtendimentoLEAN28}
                                        isAtendimentoLEAN56={this.isAtendimentoLEAN56}
                                        isAtendimentoMENTORIA={this.isAtendimentoMENTORIA}
                                        isAtendimentoDIGITAL={this.isAtendimentoDIGITAL}
                                        isAtendimentoBrasilMaisLEAN={this.isAtendimentoBrasilMaisLEAN}
                                        isMetrologia={this.isMetrologia}
                                        openAtendimento={this.openAtendimento}
                                        idProdutoBrasilProdutivo={this.idProdutoBrasilProdutivo}
                                        idProdutoBrasilProdutivoIPEA={this.idProdutoBrasilProdutivoIPEA}
                                        idProdutoBrasilProdutivoIE={this.idProdutoBrasilProdutivoIE}
                                        idProdutoBrasilProdutivoLEAN28={this.idProdutoBrasilProdutivoLEAN28}
                                        idProdutoBrasilProdutivoLEAN56={this.idProdutoBrasilProdutivoLEAN56}
                                        idProdutoBrasilProdutivoMENTORIA={this.idProdutoBrasilProdutivoMENTORIA}
                                        idConsultoriaManufatura180={this.idConsultoriaManufatura180}
                                        idProdutoIndustriaMaisAvancada={this.idProdutoIndustriaMaisAvancada}
                                        idMentoriaDigital={this.idMentoriaDigital}
                                        editarSolicitacaoStatus={this.editarSolicitacaoStatus}
                                        previsaoReceita={this.state.previsaoReceita}
                                        isMetaGoverno={this.state.isMetaGoverno}
                                        formatData={this.formatData}
                                        changeModal={this.changeModal}
                                        changeModalShowToast={this.changeModalShowToast}
                                        showToast={this.showToast}
                                        showLoading={this.showLoading}
                                        closeLoading={this.closeLoading}
                                        atualizarAtendimentos={this.atualizarAtendimentos}
                                        clearAtendimento={this.clearAtendimento}
                                        originalSolicitacaoStatus={this.state.originalSolicitacaoStatus.descricao}
                                        atendimentoTurma={this.atendimentoTurma}
                                        turma={this.turma} 
                                        refreshAtendimento={this.refreshAtendimento} 
                                        isModuloNacional={this.isModuloNacional} />
                                </Grid> }
                        </Grid>
                    </Grid>
                    <SimpleModal
                        disableClick={true}
                        className={'modal-solicitacoes-dn'}
                        isOpen={this.modalFiltro}
                        handleClose={() => this.changeModal('modalFiltro', false)}
                        type={'info'}
                        width={'624px'}>
                            <Grid container className={'modal'} style={{ alignItems: 'unset' }}>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <h2>Filtro avançado</h2>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} justify={'center'} >
                                        <Grid item xs={12}>
                                        <AutoCompleteMany
                                            key={'autoCompleteProdutoNacional'}
                                            itemlabel={'descricao'}
                                            getInput={this.changeProdutoNacional}
                                            itemTemplate={this.createTemplateProdutoNacional}
                                            itemKey={'id'}
                                            label={'Produtos Nacionais'}
                                            chipLabel={'Produto(s) selecionado(s)'}
                                            placeholder={'Busque pelo nome do produto'}
                                            id={'produtoNacional'}
                                            path={'/rest/auxiliar/ProdutoNacional/0/20?id=greater(0)&isBaseNacional=true&descricao=%'}
                                            posPath={'%'}
                                            initialOjectSelectedList={this.filter.produtoNacionalList}
                                            initialOjectSelectedListClass={'produto'} 
                                            upper={true} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AutoCompleteMany
                                                key={'autoCompleteRegional'}
                                                itemlabel={'abreviacao'}
                                                getInput={this.changeRegional}
                                                itemTemplate={this.createTemplateUnidade}
                                                itemKey={'id'}
                                                label={'Departamentos Regionais'}
                                                chipLabel={'Departamento(s) selecionado(s)'}
                                                placeholder={'Busque pelo nome do regional'}
                                                id={'departamentoRegional'}
                                                path={'/rest/gestao/Unidade/0/2?tipoUnidade.sigla=DR&unidadePai.id=29&id=less(29)&buscaLivre='}
                                                initialOjectSelectedList={this.filter.regionalList}
                                                initialOjectSelectedListClass={'unidade'} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AutoCompleteMany
                                                disabled={regionaisPath === ''}
                                                key={'autoCompleteUnidade' + regionaisPath}
                                                itemlabel={'abreviacao'}
                                                getInput={this.changeUnidade}
                                                itemTemplate={this.createTemplateUnidade}
                                                itemKey={'id'}
                                                label={'Unidades Operacionais'}
                                                chipLabel={'Unidade(s) selecionada(s)'}
                                                placeholder={'Busque pelo nome da unidade'}
                                                id={'unidadeOperacional'}
                                                path={`/rest/gestao/Unidade/0/2?tipoUnidade.sigla=UO&${regionaisPath}buscaLivre=`}
                                                initialOjectSelectedList={regionaisPath === '' ? [] : this.filter.unidadeList}
                                                initialOjectSelectedListClass={'unidade'} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p className={'font-blue'} style={{ margin: 0 }}>Período</p>
                                            <InputSelect
                                                suggestions={this.dateOptions}
                                                itemKey={'id'}
                                                itemLabel={'label'}
                                                id={'dateOption'}
                                                getInput={this.changeInput}
                                                initialSelectedItem={{id: this.dateOption}} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p className={'ou-p'} style={{ padding: '0 0px', fontSize: 15, fontWeight: '600' }}>ou</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ display: 'flex', alignItems: 'unset' }}>
                                                    <FormField type={'date'} error={''}
                                                        ref='dataInicio' id={'dataInicio'}
                                                        inputValue={this.formatData(this.filter.dataInicio)}
                                                        changeValue={this.changeInput}
                                                        icon={'calendar.svg'} />
                                                    <img src={process.env.PUBLIC_URL + '/assets/icons/arrowRight.svg'} alt='arrowIcon' style={{ padding: '0 8px', marginBottom: '10px' }} />
                                                    <FormField type={'date'} error={''} ref='dataFim' id={'dataFim'}
                                                        inputValue={this.formatData(this.filter.dataFim)}
                                                        changeValue={this.changeInput}
                                                        icon={'calendar.svg'} />
                                                </div>
                                                { this.errorDatas !== '' && 
                                                    <div className={'formField'}>
                                                        <span>{this.errorDatas}</span> 
                                                    </div> }
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }} className={'box-parecer'}>
                                                <label>Parecer</label>
                                                <FormControlLabel value='end' className={'em-analise'} label={'Em Análise'} labelPlacement='end' control={
                                                    <Checkbox defaultChecked={this.filter.emAnalise} onChange={() => { this.filter.emAnalise = !this.filter.emAnalise }} disabled={false} />} />
                                                <FormControlLabel value='end' className={'conforme'} label={'Conforme'} labelPlacement='end' control={
                                                    <Checkbox defaultChecked={this.filter.conforme} onChange={() => { this.filter.conforme = !this.filter.conforme }} disabled={false} />} />
                                                <FormControlLabel value='end' className={'nao-conforme'} label={'Não Conforme'} labelPlacement='end' control={
                                                    <Checkbox defaultChecked={this.filter.naoConforme} onChange={() => { this.filter.naoConforme = !this.filter.naoConforme }} disabled={false} />} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={'rodape'}>
                                        <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => { this.filter = Object.assign({}, this.filterCp); this.errorDatas = ''; this.changeModal('modalFiltro', false)}} />
                                        <GenericButton color={'darkBlue'} label={'Filtrar'} subClass={'basic-button'} disabled={this.errorDatas !== ''} click={() => this.changeFiltro(true) } />
                                    </div>
                                </Grid>
                            </Grid>
                        </SimpleModal>
                </Grid>
            </Page>
        );
    }
}