import React, { Component } from 'react';
import Masks from "../../comm/masks";
import FormField from "../../components/formField/formField";
import { newBackend } from '../../comm/API';
import { Grid } from '@material-ui/core';
import GenericButton from '../../components/genericButton/genericButton';

export default class RegistroCNPJ extends Component {
  constructor(props) {
    super(props);

    const cnpjList = props.cnpjValues ? props.cnpjValues.split(" ; ") : [];
    this.state = {
      numberOfCnpjs: props.numberOfCnpjs,
      cnpjList,
      cnpjsValidos: false,
      closeModal: props.closeModal,
    };
  }

  verificaValidadeCnpj = async (cnpj, showToasts) => {
    if (cnpj && cnpj !== "") {
      try {
        const response = await newBackend.get(`/busca-cnpj/novo/${cnpj}`);
        if(showToasts) {
          this.props.showToast(
            `CNPJ Válido! Razão Social: ${response.data.cliente.razaoSocial}`,
            "success"
          );
        }
        return true;
      } catch (error) {
        this.props.showToast(
          `CNPJ ${cnpj} inválido ou não encontrado na base de CNPJs. Favor solicitar o cadastro via Suporte.`,
          "error"
        );
        return false;
      }
    }
    return false;
  }

  removeDigitosEspeciaisCnpj = (cnpj) => {
    return cnpj.replace(/[^\d]+/g, "").replace(/^(\d{14}).*$/, "$1");
  };

  handleInputChange = async (index, novoValor) => {
    const cnpjList = [...this.state.cnpjList];
    let parsedValue = this.removeDigitosEspeciaisCnpj(novoValor);

    cnpjList[index] = parsedValue || "";

    this.setState({ cnpjList });

    if (parsedValue.length === 14) {
      this.verificaValidadeCnpj(parsedValue, true);
    }
  };

  verificaCnpjsValidos = async (cnpjList) => {
    let cnpjsValidos = true;

    const cnpjDuplicates = cnpjList.filter((cnpj, index) => 
      cnpj && cnpjList.indexOf(cnpj) !== index
    );

    if (cnpjDuplicates.length > 0) {
      this.props.showToast(
        `Os seguintes CNPJs estão duplicados: ${[...new Set(cnpjDuplicates)].join(", ")}.`,
        "error"
      );
      this.setState({ cnpjsValidos: false });
      return;
    }

    const promises = cnpjList.map(async (cnpj) => {
      const tamanhoOk = cnpj.length === 14 || cnpj === "";
      let cnpjValido = false;

      if(tamanhoOk) {
        cnpjValido = await this.verificaValidadeCnpj(cnpj, false);
      } else {
        cnpjValido = false;
        this.props.showToast(`CNPJ ${cnpj} precisa ter 14 caracteres.`, "error");
      }

      if(tamanhoOk && !cnpjValido) {
        this.props.showToast(
          `CNPJ ${cnpj} inválido ou não encontrado na base de CNPJs. Favor solicitar o cadastro via Suporte.`,
          "error"
        );
      }

      return tamanhoOk && cnpjValido;
    });

    const resultados = await Promise.all(promises);

    cnpjsValidos = resultados.every((resultado) => resultado === true);

    this.setState({ cnpjsValidos });
  };

  handleSubmitCnpjs = async () => {
    const { handleCnpjValues, handleNumCnpjRegistered } = this.props;
    const { cnpjList } = this.state;

    this.props.showLoading();

    let filteredCnpjList = cnpjList.filter((cnpj) => cnpj && cnpj.trim() !== "");
    this.setState({ cnpjList: filteredCnpjList });

    await this.verificaCnpjsValidos(filteredCnpjList);

    const { cnpjsValidos } = this.state;

    if (filteredCnpjList.length > this.state.numberOfCnpjs) {
      filteredCnpjList = filteredCnpjList.slice(0, this.state.numberOfCnpjs);
    }

    if (!cnpjsValidos) {
      this.props.closeLoading();
      return;
    }

    const numCnpjsRegistered = filteredCnpjList.length;

    handleCnpjValues(filteredCnpjList);
    handleNumCnpjRegistered(numCnpjsRegistered)
    this.props.closeLoading();
    this.props.closeModal();
  };

  render() {
    const inputsPerColumn = 10;
    const numberOfCnpjs = this.state.numberOfCnpjs;

    const columns = Array.from({
      length: Math.ceil(numberOfCnpjs / inputsPerColumn)
    }).map((_, colIndex) => {
      const startIndex = colIndex * inputsPerColumn;
      const endIndex = Math.min(startIndex + inputsPerColumn, numberOfCnpjs);

      return (
        <div className="input-column" key={colIndex}>
          {Array.from({ length: endIndex - startIndex }).map((_, index) => {
            const globalIndex = startIndex + index;
            const valor = this.state.cnpjList?.[globalIndex] || "";
            return (
              <FormField
                key={globalIndex}
                type={"text"}
                id={globalIndex}
                inputValue={Masks.maskByTipoPessoa(valor, 1)}
                changeValue={(e) => this.handleInputChange(globalIndex, e.target.value)}
                placeholder={`CNPJ ${globalIndex + 1}`}
              />
            );
          })}
        </div>
      );
    });

    return (
      <>
        <h3 style={{ color: '#077CDC'}} >Insira os CNPJs</h3>
        <Grid className={"cnpj-inputs"} style={{ display: "flex", gap: "16px" }}>
          {columns}
        </Grid>
        <Grid className={"button-send"} style={{ display: "flex", justifyContent: "center", marginTop: "10px"}}>
          <GenericButton
            color={"darkBlue"}
            label={"Enviar"}
            subClass={"basic-button"}
            click={this.handleSubmitCnpjs}
          />
        </Grid>
      </>
    );
  }
}
