/* https://github.com/ianmcnally/react-currency-masked-input */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toCurrency } from '../../comm/masks';

export default class CurrencyInput extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        defaultValue: PropTypes.string,
        value: PropTypes.string,
        separator: PropTypes.oneOf(['.', ',']),
        max: PropTypes.number,
    };

    static defaultProps = { separator: '.' };
    state = { value: this.props.enableEmpty && (this.props.defaultValue === 'undefined' || this.props.defaultValue === 'null') ? '' : 
            this.props.defaultValue != null && this.props.defaultValue !== '' ? toCurrency(this.props.subtype === 'inteiro' ? this.props.defaultValue :
            (this.props.subtype === 'indicador' ? Number(this.props.defaultValue).toFixed(4) :
                (this.props.subtype === 'umacasa' ? Number(this.props.defaultValue).toFixed(1) :
                    (this.props.subtype === 'seiscasas' ? Number(this.props.defaultValue).toFixed(6) :
                        Number(this.props.defaultValue).toFixed(2)))), this.props.subtype, ',') + this.props.sufix : '' };

    handleChange = event => {
        const { onChange, separator } = this.props;
        let value = event.target.value;
        const numericValue = parseFloat(value.replace(/[^0-9,.]/g, '').replace(',', '.'));
        if (!isNaN(numericValue) && numericValue > this.props.max) {
            return;
        }
        const valueAsCurrency = this.props.enableEmpty && (value === '0,0' || value === '') ? '' : toCurrency(value, this.props.subtype, separator) + this.props.sufix;
        this.setState({ value: valueAsCurrency });

        if (onChange) {
            event.persist()
            onChange(event, valueAsCurrency)
        }
    };

    get value() {
        return this.props.value || this.state.value;
    }

    handleBlur = (event) => {
        let value = event.target.value.replace(/%/g, '').replace(/\./g, '').replace(',', '.');
        event.target.value = value;
        this.props.onBlur(event);
    };

    render() {
        const { handleChange, props: { defaultValue, enableEmpty, separator, ...inputProps }, value, handleBlur} = this;
        return (
            <input type="text" {...inputProps} value={value} onChange={handleChange} onBlur={handleBlur} />
        );
    }
}