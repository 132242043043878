import React, { Component } from 'react'
import { Grid } from "@material-ui/core";

export default class Status extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Grid item xs={12} md={this.props.isDN ? 6 : 12}>
                <div className={'greyDiv'}>
                    <h2>Histórico de Status</h2>
                    {this.props.atendimento.dataEmissao != null ?
                        <p>
                            <b>Data de Criação: </b>
                            {this.props.formatDataWithSlash(this.props.atendimento.dataEmissao)}
                        </p>
                        : null}
                    {this.props.atendimento.dataNegociacao != null ?
                        <p>
                            <b>Data de Negociação: </b>
                            {this.props.formatDataWithSlash(this.props.atendimento.dataNegociacao)}
                        </p>
                        : null}
                    {this.props.atendimento.dataAceitacao != null ?
                        <p>
                            <b>Data de Aceitação: </b>
                            {this.props.formatDataWithSlash(this.props.atendimento.dataAceitacao)}
                        </p>
                        : null}
                    {this.props.atendimento.dataExecucao != null ?
                        <p>
                            <b>Data de Execução: </b>
                            {this.props.formatDataWithSlash(this.props.atendimento.dataExecucao)}
                        </p>
                        : null}
                    {this.props.atendimentoNovo?.dataencerramentotecnico != null ?
                        <p>
                            <b>Data de Encerramento Técnico: </b>
                            {this.props.formatDataWithSlash(this.props.atendimentoNovo?.dataencerramentotecnico)}
                        </p>
                        : null}
                    {this.props.atendimento.dataConclusao != null ?
                        <p>
                            <b>Data de Conclusão: </b>
                            {this.props.formatDataWithSlash(this.props.atendimento.dataConclusao)}
                        </p>
                        : null}
                    {this.props.atendimento.dataCancelamento != null ?
                        <p>
                            <b>Data de Cancelamento: </b>
                            {this.props.formatDataWithSlash(this.props.atendimento.dataCancelamento)}
                        </p>
                        : null}
                    {this.props.atendimento.dataArquivamento != null ?
                        <p>
                            <b>Data de Arquivamento: </b>
                            {this.props.formatDataWithSlash(this.props.atendimento.dataArquivamento)}
                        </p>
                        : null}
                    {this.props.atendimento.dataRecusa != null ?
                        <p>
                            <b>Data de Recusa: </b>
                            {this.props.formatDataWithSlash(this.props.atendimento.dataRecusa)}
                        </p>
                        : null}
                    {this.props.isAtendimentoBrasilMaisLEAN ? 
                        <>
                            {this.props.atendimentoJustificativa ? 
                                <p>
                                    <b>Justificativa: </b>
                                    {this.props.atendimentoJustificativa.justificativa} - {this.props.atendimentoJustificativa.detalhes}
                                </p>
                            : null}
                        </>
                        : null}
                </div>
            </Grid>
        )
    }
}