import { FormControlLabel, Grid } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import React, { Component } from 'react';
import API from '../../comm/API';
import AutoCompleteMany from '../../components/autoCompleteMany/autoCompleteMany';
import FormField from '../../components/formField/formField';
import GenericButton from '../../components/genericButton/genericButton';
import InputSelect from '../../components/inputselect/inputselect';
import SimpleModal from './../../components/modal/modal';
import './atendimento.scss';

export default class FiltroAtendimento extends Component {
    atendimentoStatusList = [];
    atendimentoStatusSIList = [];
    busca = '';
    hasFilter = false;
    unidades = [];
    unidadesCp = this.unidades;
    drsCp = this.drs;
    changeInputFlag = false;
    initFlag = true;
    flagBusca = false;
    produtoRegionalList = [];
    produtoRegionalListCp = [];
    produtoNacionalList = [];
    produtoNacionalListCp = [];
    errorDatas = '';

    filter = {
        from: this.props.pagination.from,
        to: this.props.pagination.tom,
        ordenarPor: 'id desc',
        dataInicio: new Date(),
        dataFim: new Date()
    };
    filterCp = this.filter;

    dateOption = 3;
    dateOptionCp = 3;
    dateOptions = [
        { id: 0, label: 'Últimos 30 dias' },
        { id: 1, label: 'Últimos 6 meses' },
        { id: 2, label: 'Último ano' },
        { id: 3, label: 'Últimos 10 anos' },
    ];

    statusList = {
        emelaboracao: false,
        pronto: false,
        negociacao: false,
        aceito: false,
        emexecucao: false,
        concluido: false,
        cancelado: false,
        recusado: false,
        arquivado: false
    };
    statusListCp = this.statusList;

    statusSIList = {
        validado: false,
        aguardandovalidacao: false,
        comcritica: false,
        na: false,
        enviomanuald: false,
        enviomanualv: false
    };
    statusSIListCp = this.statusSIList;

    constructor(props) {
        super(props);
        this.state = {}
        if (this.props.filterThis == null) {
            this.getAtendimentoStatusList();
            this.getAtendimentoStatusSIList();
            this.unidades = [];
            this.drs = [];
            var filter = {
                from: this.props.pagination.from,
                to: this.props.pagination.to,
                ordenarPor: 'id desc',
                dataInicio: new Date(),
                dataFim: new Date()
            };
            filter.dataInicio.setYear(this.filter.dataInicio.getFullYear() - 10);
            this.hasFilter = false;
            this.filter = filter;
            Object.keys(this.statusList).forEach(prop => {
                this.statusList[prop] = false;
            });
            Object.keys(this.statusSIList).forEach(prop => {
                this.statusSIList[prop] = false;
            });
        } else {
            this.filter = this.props.filterThis.filter;
            this.statusList = this.props.filterThis.statusList;
            this.statusSIList = this.props.filterThis.statusSIList;
            this.busca = this.props.filterThis.busca;
            this.hasFilter = true;
            this.dateOption = this.props.filterThis.dateOption;
            this.atendimentoStatusList = this.props.filterThis.atendimentoStatusList;
            this.atendimentoStatusSIList = this.props.filterThis.atendimentoStatusSIList;
            this.unidades = this.props.filterThis.unidades;
            this.drs = this.props.filterThis.drs;
            this.produtoRegionalList = this.props.filterThis.produtoRegionalList;
            this.produtoNacionalList = this.props.filterThis.produtoNacionalList;
            var aux = [];
            this.atendimentoStatusList.forEach(element => {
                if (this.statusList[element.chave]) {
                    aux.push(element.id);
                }
            });
            this.filter['atendimentoStatus'] = aux;
            var aux2 = [];
            this.atendimentoStatusSIList.forEach(element => {
                if (this.statusSIList[element.chave]) {
                    aux2.push(element.id);
                }
            });
            this.filter['atendimentoStatusSI'] = aux2;
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.pagination) {
            this.changeRange();
        }
    }

    changeRange = () => {
        if ((this.props.pagination.from !== this.filter.from) && (this.props.pagination.to !== this.filter.to)) {
            this.filter.from = this.props.pagination.from;
            this.filter.to = this.props.pagination.to;
            this.flagBusca = false;
            this.search();
        }
    }

    getAtendimentoStatusList = () => {
        API.get('/rest/gestao/AtendimentoStatus').then(res => {
            this.atendimentoStatusList = res.data
            this.setState(this.state);
        }).catch(error => {
            console.log(error);
        });
    }

    getAtendimentoStatusSIList = () => {
        API.get('/rest/gestao/AtendimentoStatusSI').then(res => {
            this.atendimentoStatusSIList = res.data
            this.setState(this.state);
        }).catch(error => {
            console.log(error);
        });
    }

    createTemplateUnidade(unidade) {
        return (
            <div className={"auto-complete-options"}>
                <div>
                    <label>{unidade.descricao}</label>
                </div>
                <div>
                    <p>{unidade.municipio.descricao} - {unidade.municipio.unidadeFederativa.sigla}</p>
                </div>
            </div>
        );
    }

    createTemplateProdutoRegional(produto) {
        return (
            <div className={"auto-complete-options"}>
                <div>
                    <span className={"tipoUnidadeParametro"}>{produto.unidade.municipio.unidadeFederativa.sigla}</span>
                    <label>{produto.nome}</label>
                </div>
                <div>
                    <p>{produto.produtoNacional.descricao}</p>
                    <p>{produto.produtoNacional.produtoCategoria.descricao} - {produto.produtoNacional.produtoCategoria.produtoLinha.descricao}</p>
                </div>
            </div>
        );
    }

    createTemplateProdutoNacional(produto) {
        return (
            <div className={"auto-complete-options"}>
                <div>
                    <label>{produto.descricao}</label>
                </div>
                <div>
                    <p>{produto.produtoCategoria.descricao} - {produto.produtoCategoria.produtoLinha.descricao}</p>
                    <p>Produto de base nacional: {produto.isBaseNacional ? 'SIM' : 'NÃO'}</p>
                </div>
            </div>
        );
    }

    changeInput = (e) => {
        if (e != null && e.target != null) {
            this.initFlag = false;
            this.flagBusca = false;
            if (e.target.type === 'checkbox') {
                if (this.initFlag === false) this.changeInputFlag = true;
                this[e.target.id][e.target.value] = e.target.checked
            } else if (e.target.type === 'date' && e.target.value) {
                if (this.initFlag === false) this.changeInputFlag = true;
                this.filter[e.target.id] = e.target.value;
                this.dateOption = undefined;
                if (this.props.formatData(this.filter.dataInicio) > this.props.formatData(this.filter.dataFim)) this.errorDatas = 'Intervalo de datas inválido.';
                else this.errorDatas = '';
            } else if (e.target.type === 'text') {
                this.changeBuscaFlag = true;
                this.busca = e.target.value;
            }
            this.setState(this.state);
        } else if (e.id != null) {
            if (this.initFlag === false) this.changeInputFlag = true;
            this.initFlag = false;
            this.dateOption = e.id;
            this.filter.dataFim = new Date();
            this.filter.dataInicio = new Date();
            if (e.id === 0) {
                this.filter.dataInicio.setDate(this.filter.dataInicio.getDate() - 30);
            } else if (e.id === 1) {
                this.filter.dataInicio.setMonth(this.filter.dataInicio.getMonth() - 6);
            } else if (e.id === 2) {
                this.filter.dataInicio.setYear(this.filter.dataInicio.getFullYear() - 1);
            } else {
                this.filter.dataInicio.setYear(this.filter.dataInicio.getFullYear() - 10);
            }
            this.setState(this.state);
        }
    }

    changeUnidades = (e) => {
        if (e != null) {
            this.unidades = e;
            this.changeInputFlag = true;
            this.setState(this.state);
        }
    }

    changeDRs = (e) => {
        if (e != null) {
            this.drs = e;
            let iDs = this.drs.map(item => item.unidade.id);
            let produtosRegionais = this.produtoRegionalList.slice();
            let unidades = this.unidades.slice();
            produtosRegionais = produtosRegionais.filter(item => iDs.includes(item.unidade.unidade.id));
            unidades = unidades.filter(item => iDs.includes(item.unidade.id));
            this.produtoRegionalList = produtosRegionais;
            this.unidades = unidades;
            this.changeInputFlag = true;
            this.setState(this.state);
        }
    }

    changeProdutoRegional = (produtoRegionalSelected) => {
        if (produtoRegionalSelected) {
            this.produtoRegionalList = produtoRegionalSelected;
            this.changeInputFlag = true;
            this.setState(this.state);
        }
    };

    changeProdutoNacional = (produtos) => {
        if (produtos) {
            this.produtoNacionalList = produtos;
            let iDs = this.produtoNacionalList.map(item => item.produto.id);
            let produtosRegionais = this.produtoRegionalList.slice(); 
            produtosRegionais = produtosRegionais.filter(item => iDs.includes(item.unidade.produtoNacional.id));
            this.produtoRegionalList = produtosRegionais;
            this.changeInputFlag = true;
            this.setState(this.state);
        }
    };

    createQueryPath = (path, attr, filterProdNac) => {
        const unidadeId = this.props.isUO ? this.props.usuarioConectado.unidade.unidadePai.id : this.props.usuarioConectado.unidade.id;
        if (this.drs.length > 0) {
            const unidades = this.drs.map(item => { return `${attr}.id=${item.unidade.id}&`; }).join('');
            path = path.replace('$1', unidades);
        }
        else path = path.replace('$1', `${attr}.id=${unidadeId}&`);
        path = filterProdNac ? path.replace('$2', this.produtoNacionalList.map(item => { return `produtoNacional.id=${item.produto.id}&`; }).join('')) : path.replace('$2', '');
        return path;
    }

    getProdutoRegionalPath = () => {
        let path = '/rest/auxiliar/ProdutoRegional/0/2?isAtivo=true&$1$2buscaLivreProdutoRegional=';
        path = this.createQueryPath(path, 'unidade', true);
        return path;
    }

    getUnidadePath = () => {
        let path = `/rest/gestao/Unidade/0/2?tipoUnidade.sigla=UO&$1buscaLivre=`;
        path = this.createQueryPath(path, 'unidadePai');
        return path;
    }

    changeFiltro = () => {
        if (this.changeInputFlag || this.changeBuscaFlag) {
            this.filter.from = 0;
            this.filter.to = 49;
            this.props.resetRange();
            var aux = [];
            this.atendimentoStatusList.forEach(element => {
                if (this.statusList[element.chave]) {
                    aux.push(element.id);
                }
            });
            this.filter['atendimentoStatus'] = aux;

            var aux2 = [];
            this.atendimentoStatusSIList.forEach(element => {
                if (this.statusSIList[element.chave]) {
                    aux2.push(element.id);
                }
            });
            this.filter['atendimentoStatusSI'] = aux2;
            this.hasFilter = this.changeBuscaFlag && !this.changeInputFlag ? false : true;
            this.flagBusca = true;
            this.search();
        }
        this.props.changeModal('modalFiltro', false);
    }

    getBasicFilter = () => {
        var filter = {
            from: 0,
            to: 49,
            ordenarPor: 'id desc',
            dataInicio: new Date(),
            dataFim: new Date()
        };
        this.errorDatas = '';
        this.props.resetRange();
        filter.dataInicio.setYear(this.filter.dataInicio.getFullYear() - 10);
        this.hasFilter = false;
        this.flagBusca = false;
        this.unidades = [];
        this.drs = [];
        this.produtoRegionalList = [];
        this.produtoNacionalList = [];
        this.filter = filter;
        this.changeInputFlag = false;
        Object.keys(this.statusList).forEach(prop => {
            this.statusList[prop] = false;
        });
        Object.keys(this.statusSIList).forEach(prop => {
            this.statusSIList[prop] = false;
        });
        this.search();
    }

    search = async () => {
        if (this.changeInputFlag && !this.flagBusca) this.busca = '';
        if (this.changeInputFlag) this.changeInputFlag = false;
        var filter = new URLSearchParams();
        filter.append('from', this.filter.from);
        filter.append('ordenarPor', this.filter.ordenarPor);
        filter.append('atendimento.dataEmissao', "between(" + this.props.formatData(this.filter.dataInicio) + 'T00:00:00-03:00,' + this.props.formatData(this.filter.dataFim) + 'T00:00:00-03:00)');
        if (this.filter.atendimentoStatus != null) {
            this.filter.atendimentoStatus.forEach(element => {
                filter.append('atendimentoStatus.id', element);
            });
        }
        if (this.filter.atendimentoStatusSI != null) {
            this.filter.atendimentoStatusSI.forEach(element => {
                filter.append('atendimentoStatusSI.id', element);
            });
        }
        if (this.unidades.length > 0 && !this.props.isUO) {
            this.unidades.forEach(element => {
                filter.append('unidade.id', element.unidade.id);
            });
        }
        if (this.drs.length > 0 && this.props.isDN) {
            this.drs.forEach(element => {
                filter.append('unidadePai.id', element.unidade.id);
            });
        }
        if (this.produtoRegionalList.length > 0) {
            this.produtoRegionalList.forEach(element => {
                filter.append('produtoRegional.id', element.unidade.id);
            });
        }
        if (this.produtoNacionalList.length > 0 && this.props.isDN) {
            this.produtoNacionalList.forEach(element => {
                filter.append('produtoNacional.id', element.produto.id);
            });
        }
        if (this.props.isDR) {
            filter.append("unidadePai.id", this.props.usuarioConectado.unidade.id);
        } else if (this.props.isUO) {
            filter.append("unidade.id", this.props.usuarioConectado.unidade.id);
        } 
        if (this.busca !== undefined && this.busca !== '') {
            filter.append('atendimento.buscaLivreAtendimento', this.busca);
        }
        this.props.getFilterResult(filter, this.filter.from, this.filter.to, this);
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={this.props.isDN ? 7 : 8} xl={this.props.isDN ? 4 : 8}>
                    <FormField type={'text'} inputValue={this.busca}
                        changeValue={this.changeInput}
                        pressEnter={() => { this.flagBusca = true; this.changeBuscaFlag = true; this.changeFiltro() }}
                        title={'Busque por ID, Nº, proposta, título, cliente, produto ou setor'}
                        id={'busca'} placeholder={'Busque por ID, Nº, proposta, título, cliente, produto ou setor'}
                        button={<GenericButton color={'darkBlue'} subClass={'icon-button'}
                            icon={process.env.PUBLIC_URL + 'search.svg'}
                            click={() => { this.flagBusca = true; this.changeBuscaFlag = true; this.changeFiltro() }} />} />
                </Grid>
                <Grid item xs={12} sm={this.props.isDN ? 5 : 4} xl={this.props.isDN ? 2 : 4} style={{ display: 'flex', height: '30px' }}>
                    <GenericButton color={'lightBlue'} label={'Busca avançada'} subClass={'basic-button busca-avancada'}
                        icon={process.env.PUBLIC_URL + 'filter.svg'}
                        click={() => {this.filterCp = Object.assign({}, this.filter); this.statusListCp = Object.assign({}, this.statusList);
                                    this.statusSIListCp = Object.assign({}, this.statusSIList); this.unidadesCp = this.unidades.slice(); this.drsCp = this.drs.slice();
                                    this.dateOptionCp = this.dateOption; this.initFlag = true; this.produtoNacionalListCp = this.produtoNacionalList;
                                    this.produtoRegionalListCp = this.produtoRegionalList; this.props.changeModal('modalFiltro', true)}} />
                    {this.hasFilter &&
                        <GenericButton color={'white'} subClass={'icon-button'} click={this.getBasicFilter} icon={'close.svg'} /> }
                </Grid>
                <SimpleModal
                    isOpen={this.props.modalFiltro}
                    handleClose={() => this.props.changeModal('modalFiltro', false)}
                    type={'info'}
                    disableClick={true}
                    width={'624px'}>
                    <div className={'modal modal-atendimento-filtro'}>
                        <h2>Busca avançada</h2>
                        <Grid container style={{ alignItems: 'center' }}>
                            <Grid item xs={12}>
                                { this.props.isDN &&
                                    <AutoCompleteMany
                                        key={'autoCompleteProdutoNacional'}
                                        itemlabel={'descricao'}
                                        getInput={ (e) => this.changeProdutoNacional(e) }
                                        itemTemplate={this.createTemplateProdutoNacional}
                                        itemKey={'id'}
                                        label={'Produtos Nacionais'}
                                        chipLabel={'Produto(s) selecionado(s)'}
                                        placeholder={'Busque pela descrição do produto. Ex: CONSULTORIA EM GESTÃO DA MARCA'}
                                        id={'produtoNacional'}
                                        path={'/rest/auxiliar/ProdutoNacional/0/20?id=greater(0)&descricao=%'}
                                        posPath={'%'}
                                        initialOjectSelectedList={this.produtoNacionalList}
                                        initialOjectSelectedListClass={'produto'}  /> }
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                {this.props.isDN &&
                                    <AutoCompleteMany
                                        key={'autoCompleteDR'}
                                        itemlabel={'abreviacao'}
                                        getInput={this.changeDRs}
                                        itemTemplate={this.createTemplateUnidade}
                                        itemKey={'id'}
                                        label={'Departamento Regional (DR)'}
                                        chipLabel={'DR(s) selecionado(s)'}
                                        placeholder={'Ex: SENAI DR'}
                                        id={'colaboradorUnidade'}
                                        path={`/rest/gestao/Unidade/0/2?tipoUnidade.sigla=DR&unidadePai.id=${this.props.usuarioConectado.unidade.id}&buscaLivre=`}
                                        initialOjectSelectedList={this.drs}
                                        initialOjectSelectedListClass={'unidade'} />}

                                <AutoCompleteMany
                                    key={'autoCompleteProdutoRegional' + this.produtoNacionalList + this.drs}
                                    itemlabel={'nome'}
                                    disabled={(this.props.isDN && (this.drs.length === 0 || this.produtoNacionalList.length === 0))}
                                    getInput={ (e) => this.changeProdutoRegional(e) }
                                    itemTemplate={this.createTemplateProdutoRegional}
                                    itemKey={'id'}
                                    label={'Produtos Regionais'}
                                    chipLabel={'Produto(s) selecionado(s)'}
                                    placeholder={'Ex: Serviços de Moldagem'}
                                    id={'produtoRegional'}
                                    path={this.getProdutoRegionalPath()}
                                    initialOjectSelectedList={this.produtoRegionalList}
                                    initialOjectSelectedListClass={'unidade'} />
                                
                                {!this.props.isUO && <AutoCompleteMany
                                    key={'autoCompleteUnidade' + this.drs}
                                    itemlabel={'descricao'}
                                    disabled={(this.props.isDN && !(this.drs.length > 0))}
                                    getInput={this.changeUnidades}
                                    itemTemplate={this.createTemplateUnidade}
                                    itemKey={'id'}
                                    label={'Unidade'}
                                    chipLabel={'Unidade(s) selecionada(s)'}
                                    placeholder={'Ex: Insituto Senai de Inovação'}
                                    id={'colaboradorUnidade'}
                                    path={this.getUnidadePath()}
                                    initialOjectSelectedList={this.unidades}
                                    initialOjectSelectedListClass={'unidade'} />}
                                
                                <p className={'font-blue'} style={{ margin: 0 }}>Período</p>

                                <InputSelect
                                    suggestions={this.dateOptions}
                                    itemKey={'id'}
                                    itemLabel={'label'}
                                    id={'dateOption'}
                                    getInput={this.changeInput}
                                    initialSelectedItem={{id: this.dateOption}} />
                                <Grid item xs={12}>
                                    <p className={'ou-p'} style={{ padding: '0 0px', fontSize: 15, fontWeight: '600' }}>ou</p>
                                </Grid>
                                <Grid item xs={this.props.isDN ? 6 : 12}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', alignItems: 'unset' }}>
                                            <FormField type={'date'} error={''}
                                                ref='dataInicio' id={'dataInicio'}
                                                inputValue={this.props.formatData(this.filter.dataInicio)}
                                                changeValue={this.changeInput}
                                                icon={'calendar.svg'} />
                                            <img src={process.env.PUBLIC_URL + '/assets/icons/arrowRight.svg'} alt='arrowIcon' style={{ padding: '0 8px', marginBottom: '10px' }} />
                                            <FormField type={'date'} error={''} ref='dataFim' id={'dataFim'}
                                                inputValue={this.props.formatData(this.filter.dataFim)}
                                                changeValue={this.changeInput}
                                                icon={'calendar.svg'} />
                                        </div>
                                        { this.errorDatas !== '' && 
                                            <div className={'formField'}>
                                                <span>{this.errorDatas}</span> 
                                            </div> }
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid container>
                            <Grid item xs={this.props.isDN ? 7 : 12} className={'flex-column status'}>
                                <p className={'font-blue'}>Status</p>
                                {this.atendimentoStatusList.map(item => (
                                    <FormControlLabel key={item.id} className={`tick-item ${item.chave}`} label={item.descricao}
                                        control={<Checkbox
                                            id={'statusList'}
                                            checked={this.statusList[item.chave]}
                                            value={item.chave}
                                            onChange={this.changeInput} />} />
                                ))}
                            </Grid>
                            {this.props.isDN ?
                                <Grid item xs={5} className={'flex-column'}>
                                    <p className={'font-blue'}>Situação</p>
                                    {this.atendimentoStatusSIList.map(item => (
                                        <FormControlLabel key={item.id} className={`tick-item`} label={item.descricao}
                                            control={<Checkbox
                                                id={'statusSIList'}
                                                checked={this.statusSIList[item.chave]}
                                                value={item.chave}
                                                onChange={this.changeInput} />} />
                                    ))}
                                </Grid>
                                :
                                <div></div>
                            }
                        </Grid>
                        <div className={'rodape'}>
                            <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'}
                                click={() => { this.changeInputFlag = false; this.filter = this.filterCp; this.statusList = this.statusListCp; this.statusSIList = this.statusSIListCp; 
                                    this.unidades = this.unidadesCp; this.drs = this.drsCp; this.produtoRegionalList = this.produtoRegionalListCp; this.produtoNacionalList = this.produtoNacionalListCp; 
                                    this.dateOption = this.dateOptionCp; this.errorDatas = ''; this.props.changeModal('modalFiltro', false); }} />
                            <GenericButton color={'darkBlue'} label={'Filtrar'} subClass={'basic-button'} disabled={this.errorDatas !== ''} click={this.changeFiltro} />
                        </div>
                    </div>
                </SimpleModal>
            </Grid>
        )
    }
}