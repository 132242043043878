import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import API from "../../comm/API";
import FormField from "../../components/formField/formField";
import GenericButton from "../../components/genericButton/genericButton";
import Loading from "./../../components/loading/loading";
import SimpleModal from "./../../components/modal/modal";
import Toast from "./../../components/toast/toast";

export default class Comentario extends Component {
  comentario = this.props.atendimento.comentario;

  constructor(props) {
    super(props);
    this.state = {};
  }

  changeComentario = (e) => {
    if (e != null && e.target != null) {
      this[e.target.id] = e.target.value;
      this.setState(this.state);
    }
  };
  saveComentario = () => {
    this.props.showLoading();
    var atendimentoAux = this.props.atendimento;
    atendimentoAux.comentario = this.comentario != null && this.comentario != "" ? this.comentario.replace(/[^\x00-\x7F]/g, "") : "";
    /* atendimentoAux.comentario = this.comentario */
    API.post("rest/gestao/Atendimento/" + atendimentoAux.id, atendimentoAux)
      .then((res) => {
        this.props.closeLoading();
        this.props.changeModal("modalComentario", false);
        this.props.showToast("Comentário salvo com sucesso!", "success");
      })
      .catch((error) => {
        this.props.showToast("Erro ao salvar comentário. Favor, entrar em contato com o suporte.", "error");
      });
  };
  clearComentario = () => {
    if (this.props.atendimento.comentario != null) {
      this.comentario = this.props.atendimento.comentario;
    } else {
      this.comentario = null;
    }
    this.setState(this.state);
    this.props.changeModal("modalComentario", false);
  };

  render() {
    return (
      <Grid item xs={12}>
        <div className={"greyDiv"}>
          <GenericButton
            color={"darkBlue"}
            subClass={"basic-button"}
            click={() => this.props.changeModal("modalComentario", true)}
            label={this.props.atendimento.comentario != null ? "Editar comentário" : "Adicionar comentário"}
          />
          <h2>Comentário</h2>
          {this.props.atendimento.comentario ? <p>{this.props.atendimento.comentario}</p> : <p>Este atendimento ainda não possui comentários.</p>}
        </div>

        <div>
          <Toast getFunctions={this.getFunctions} />
          <SimpleModal isOpen={this.props.modalComentario} handleClose={() => this.props.changeModal("modalComentario", false)} type={"info"} width={"400px"}>
            <Loading getFunctions={this.getFunctions} />
            <div className={"modal modal-comentario"}>
              <h2>{this.props.atendimento.comentario != null ? "Editar Comentário" : "Adicionar Comentário"}</h2>
              <Grid container>
                <Grid item xs={12}>
                  <FormField type={"textarea"} id={"comentario"} placeholder={"Escreva seu comentário."} inputValue={this.comentario} maxLength={"6000"} changeValue={this.changeComentario} />
                </Grid>
                <Grid item xs={12} className={"rodape"}>
                  <GenericButton color={"darkGrey-outline"} label={"Cancelar"} subClass={"basic-button"} click={() => this.clearComentario()} />

                  <GenericButton
                    color={"darkBlue"}
                    label={this.comentario != null ? "Editar" : "Adicionar"}
                    subClass={"basic-button"}
                    click={this.saveComentario}
                    disabled={this.comentario === this.props.atendimento.comentario || this.comentario === ""}
                  />
                </Grid>
              </Grid>
            </div>
          </SimpleModal>
        </div>
      </Grid>
    );
  }
}
