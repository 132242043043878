import React, { Component } from 'react';
import './atendimento.scss';
import GenericButton from '../../components/genericButton/genericButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Table from '../../components/table/table';
import { Grid } from "@material-ui/core";
import Page from './../../components/page/page';
import API, { BASE_URL, newBackend } from '../../comm/API';
import FiltroAtendimento from './filtroAtendimento';
import Proposta from './proposta';
import Resumo from './resumo';
import { Link } from 'react-router-dom';
import EmptyList from '../../components/emptyList/emptyList';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

export default class Atendimento extends Component {
    atendimentoList = [];
    atendimento = null;
    canChangeStatus = false;

    isDN = false
    isDR = false
    isUO = false

    modalFiltro = false;
    modalVisualizarProposta = false;
    modalRegistrarProposta = false;

    isAtendimentoBP = false;
    isAtendimentoIA = false;
    isAtendimentoIPEA = false;
    isAtendimentoLEAN28 = false;
    isAtendimentoLEAN56 = false;
    isAtendimentoMENTORIA = false;
    isAtendimentoLEAN = false;
    isAtendimentoIE = false;
    isAtendimentoDIGITAL = false;
    isAtendimentoBrasilMaisLEAN = false;
    isMetrologia = false;
    isInovacao = false;
    isPronto = false;
    isNegociacao = false;
    isAceito = false;
    isExecucao = false;
    isCancelado = false;
    isConcluido = false;
    indicadores = null;
    turma = null;
    atendimentoTurma = null;
    isModuloNacional = false;
    resumo = false;
    mentoresDados = null;

    receitaApropriadaTotal = 0;
    receitaFaturamentoTotal = 0;

    filterThis = undefined;
    pagination = {
        from: 0,
        to: 49,
        total: 0
    }

    titles = [
        { id: 'id', canOrder: false, label: 'ID' },
        { id: 'numero', canOrder: false, label: 'Nº' },
        { id: 'titulo', canOrder: false, label: 'Título' },
        { id: 'cliente', canOrder: false, label: 'Cliente' },
        { id: 'proposta', canOrder: false, label: 'Proposta' },
        { id: 'produto', canOrder: false, label: 'Produto Regional' },
        { id: 'produtoNacional', canOrder: false, label: 'Produto Nacional' },
        { id: 'regional', canOrder: false, label: 'DR' },
        { id: 'unidade', canOrder: false, label: 'Unidade' },
        { id: 'producao', canOrder: false, label: 'Produção' },
        { id: 'recursos', canOrder: false, label: 'Recursos' },
        { id: 'status', canOrder: false, label: 'Status' }
    ];

    lines = [];

    atendimentoJustificativa = null;

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.closeLoading();
        if (this.props.history.location.state) {
            this.openAtendimento(this.props.history.location.state.id);
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            render: false,
            totalAtendimentos: 0,
            ticketMedio: 0,
            emptyList: 'seeResults'
        }
        this.childFiltro = React.createRef();
        //Verifica se usuário tem colaborador ativo, se sim, ele é UO.
        this.isUO = (this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'UO');
        this.isDN = (this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DN');
        this.isDR = (this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DR');

        //Atualizando as colunas da tabela exibida em atendimento
        if (this.isUO) {
            let titlesAux = this.titles.filter((item) => {
                return item.id !== 'proposta' && item.id !== 'produtoNacional' && item.id !== 'regional';
            });
            this.titles = titlesAux;
        } else if (this.isDR) {
            let titlesAux = this.titles.filter((item) => {
                return item.id !== 'produto' && item.id !== 'regional';
            });
            this.titles = titlesAux;
        } else if (this.isDN) {
            let titlesAux = this.titles.filter((item) => {
                return item.id !== 'produto' && item.id !== 'producao' && item.id !== 'recursos';
            });
            this.titles = titlesAux;
        }
    };

    getAtendimentoJustificativa = async (idAtendimento) => {
        API.get(`/rest/gestao/AtendimentoJustificativa/findAtendimento/${idAtendimento}`).then(res => {
            this.atendimentoJustificativa = res.data;
            this.setState(this.state);
        }).catch(error => {
            this.showToast('Infelizmente houve um erro ao tentar carregar os dados (justificativa). Por favor contate o suporte.', 'error');
        });
    }

    openAtendimento = async (id) => {
        this.showLoading();
        try {
           const res = await API.get(`/rest/gestao/Atendimento/${id}`);
           const resAtendimentoBackNovo = await newBackend.get(`atendimento/resumo/${id}`, { withCredentials: true });
           this.atendimentoBackNovo = resAtendimentoBackNovo.data;
           this.atendimento = res.data;
           await this.getParameters();
           await this.getTurma(id);
           await this.getAtendimentoJustificativa(this.atendimento.id);
           await this.canChangeStatusAtendimento();
           if(!this.isAtendimentoDIGITAL && !this.isAtendimentoBrasilMaisLEAN){
               await this.getIndicadores();
           }           
           await this.getStatus();
           this.resumo = true;
           this.closeLoading();

        } catch (error) {
            this.closeLoading();
        }

    };

    getTurma = async (idAtendimento) => {
        try {
            const response = await API.get(`/rest/gestao/${this.isAtendimentoDIGITAL ? 'TurmaMentoriaDigital' : 'Turma'}/findByAtendimento?idAtendimento=${idAtendimento}`);

            let mentoresDados = ''
            

            const { data } = response;
            if (data != null && (data.type === 'turma' || data.type === 'turmaMentoriaDigital')) {
                this.turma = data;
                if (data.type === 'turmaMentoriaDigital') {
                    await API.get('rest/gestao/TurmaMentoriaDigital/mentoresDados/' + this.turma.id).then(res =>{
                        this.mentoresDados = res.data;
                    })
                }                
                const { atendimentoTurmaList } = this.turma;
                this.atendimentoTurma = atendimentoTurmaList.find(e => { return e.atendimento.id === idAtendimento });
            } else if (data != null && data.atendimento != null) {
                this.turma = data.turma;
                this.atendimentoTurma = data;
            } else {
                this.turma = null;
                this.atendimentoTurma = null;
            }

            await this.setState(this.state);
        } catch (error) {
            console.log(error);
            this.showToast('Ocorreu um erro ao tentar obter os dados da turma. Por favor, entre em contato com o suporte.', 'error');
        }

    };

    canChangeStatusAtendimento = () => {
        var canChangeStatusAux = false;

        if (this.isDN) {
            return false;
        } else {
            var idUnidade = 0;
            if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
                idUnidade = this.props.usuarioConectado.colaborador.unidade.id;
            } else {
                idUnidade = this.props.usuarioConectado.unidade.id;
            }
            canChangeStatusAux = (idUnidade === this.atendimento.unidade.id) || (idUnidade === this.atendimento.unidade.unidadePai.id);
        }
        this.canChangeStatus = canChangeStatusAux && this.atendimento.atendimentoStatus && [2, 3, 4, 8].includes(this.atendimento.atendimentoStatus.id);
    }

    getParameters = async () => {
        try {
            const res = await API.get('/rest/gestao/ParametroUnidade/produtos');
            this.parameterList = res.data;
            this.idProdutoBrasilProdutivo = parseInt(this.parameterList.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO);
            this.idProdutoIndustriaMaisAvancada = parseInt(this.parameterList.ID_INDUSTRIA_MAIS_AVANCADA);
            this.idProdutoBrasilProdutivoIPEA = parseInt(this.parameterList.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_IPEA);
            this.idProdutoBrasilProdutivoIE = parseInt(this.parameterList.ID_EFICIENCIA_ENERGETICA);
            this.idProdutoBrasilProdutivoLEAN28 = parseInt(this.parameterList.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_LEAN_28);
            this.idProdutoBrasilProdutivoLEAN56 = parseInt(this.parameterList.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_LEAN_56);
            this.idProdutoBrasilProdutivoMENTORIA = parseInt(this.parameterList.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_MENTORIA);
            this.idConsultoriaManufatura180 = parseInt(this.parameterList.ID_CONSULTORIA_MANUFATURA_180);
            this.idMentoriaDigital = parseInt(this.parameterList.ID_MENTORIA_DIGITAL);
            this.idProdutoBrasilMaisLEAN = parseInt(this.parameterList.ID_PRODUTO_BRASIL_MAIS_MENTORIA_LEAN);

            this.isAtendimentoBP = (this.atendimento.produtoRegional.produtoNacional.id === this.idProdutoBrasilProdutivo);
            this.isAtendimentoIA = (this.atendimento.produtoRegional.produtoNacional.id === this.idProdutoIndustriaMaisAvancada);
            this.isAtendimentoIPEA = (this.atendimento.produtoRegional.produtoNacional.id === this.idProdutoBrasilProdutivoIPEA);
            this.isAtendimentoIE = (this.atendimento.produtoRegional.produtoNacional.id === this.idProdutoBrasilProdutivoIE)
            this.isAtendimentoLEAN28 = (this.atendimento.produtoRegional.produtoNacional.id === this.idProdutoBrasilProdutivoLEAN28);
            this.isAtendimentoLEAN56 = (this.atendimento.produtoRegional.produtoNacional.id === this.idProdutoBrasilProdutivoLEAN56);
            this.isAtendimentoMENTORIA = (this.atendimento.produtoRegional.produtoNacional.id === this.idProdutoBrasilProdutivoMENTORIA);
            this.isAtendimentoLEAN = this.isAtendimentoLEAN28 || this.isAtendimentoLEAN56 || this.isAtendimentoMENTORIA;
            this.isAtendimentoIA180 = (this.atendimento.produtoRegional.produtoNacional.id === this.idConsultoriaManufatura180);
            this.isAtendimentoDIGITAL = (this.atendimento.produtoRegional.produtoNacional.id === this.idMentoriaDigital);
            this.isAtendimentoBrasilMaisLEAN = (this.atendimento.produtoRegional.produtoNacional.id === this.idProdutoBrasilMaisLEAN);

            this.isModuloNacional = this.atendimento.produtoRegional.produtoNacional.isBaseNacional && !JSON.stringify(this.parameterList).includes(this.atendimento.produtoRegional.produtoNacional.id);

            this.setState(this.state);
        } catch (error) {
            this.showToast('Infelizmente houve um erro ao listar os parâmetros. Por favor contate o suporte.', 'error');
            
        }
    }
    getIndicadores = async () => {
        API.get('/rest/gestao/Indicadores?atendimento.id=' + this.atendimento.id).then(res => {
            if (res.data.length > 0) {
                this.indicadores = res.data;
                this.setState(this.state);
            }
            this.closeLoading();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Infelizmente houve um erro ao listar os parâmetros. Por favor contate o suporte.', 'error');
        });
    }
    getStatus = () => {
        this.isMetrologia = this.atendimento.produtoRegional.produtoNacional.produtoCategoria.produtoLinha.id === 3;
        this.isInovacao = this.atendimento.produtoRegional.produtoNacional.produtoCategoria.produtoLinha.id === 2;
        this.isSTE = this.atendimento.produtoRegional.produtoNacional.produtoCategoria.produtoLinha.id === 4;
        this.isArquivado = this.atendimento.atendimentoStatus.chave === 'arquivado';
        this.isPronto = this.atendimento.atendimentoStatus.chave === 'pronto';
        this.isNegociacao = this.atendimento.atendimentoStatus.chave === 'negociacao';
        this.isAceito = this.atendimento.atendimentoStatus.chave === 'aceito';
        this.isExecucao = this.atendimento.atendimentoStatus.chave === 'emexecucao';
        this.isConcluido = this.atendimento.atendimentoStatus.chave === 'concluido';
        this.isCancelado = this.atendimento.atendimentoStatus.chave === 'cancelado';
    }
    clearAtendimento = () => {
        this.turma = null;
        this.atendimentoTurma = null;
        this.atendimento = null;
        this.resumo = false;
        this.setState(this.state);
    }
    formatData(date) {
        if (date != null) {
            if (typeof (date) !== 'string') {
                let dataList = date.toLocaleDateString().split('/');
                return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
            } else {
                if (date.length > 10) {
                    var dataList = date.split('T');;
                    return dataList[0];
                } else {
                    return date;
                }
            }
        }
    }

    changeModal = (modal, status) => {
        this[modal] = status;
        if ((modal === 'modalFase') && status) {
            this.atendimentoFase = { fase: null, dataFinal: null, quantidadeDeHoras: 0 }
        }
        this.setState(this.state);
    }

    changeModalShowToast = (modal, status, msg) => {
        this[modal] = status;
        if ((modal === 'modalFase') && status) {
            this.atendimentoFase = { fase: null, dataFinal: null, quantidadeDeHoras: 0 }
        }
        this.setState(this.state);
        this.showToast(msg, 'success');
    }

    getLinesAtendimento = () => {
        this.atendimentoList.forEach(atd => {
            var line = []
            line.id = atd[0];
            line.numero = atd[1];
            line.cliente = atd[2];

            //Produto aparece apenas para UO
            if (this.isUO) { line.produto = atd[3] };

            line.unidade = atd[4];
            line.titulo = atd[5];

            var numeroDeProducaoEstimada = atd[7].toLocaleString('pt-BR');
            var numeroDeRelatorioEstimado = atd[9].toLocaleString('pt-BR');

            //Caso seja metrologia, mostrar ensaios e relatórios; Caso contrário, mostrar horas.
            if (!atd[6]) {
                line.producao = numeroDeProducaoEstimada + 'h';
            } else {
                line.producao =
                    <div>
                        <p>{numeroDeProducaoEstimada} Ensaios</p>
                        <p>{numeroDeRelatorioEstimado} Relatórios ou Laudos</p>
                    </div>

            }
            line.recursos = 'R$ ' + atd[10].toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 });

            //Situação aparece apenas para DN e DR
            if (this.isDN || this.isDR) { line.situacao = atd[11] };

            line.status = <label className={`${atd[13]}`}>{atd[12]}</label>;
            line.proposta = atd[16];
            line.produtoNacional = atd[17];
            line.regional = atd[18];
            this.lines.push(line);
        });
        this.setState(this.state);
        this.closeLoading();
    }

    changeRange = (e) => {
        if (e === 0) {
            this.pagination.from = 0;
            this.pagination.to = 49;
        } else if (e === 1) {
            this.pagination.from = this.pagination.from + 50;
            this.pagination.to = this.pagination.to + 50;
        } else if (e === -1) {
            this.pagination.from = this.pagination.from - 50;
            this.pagination.to = this.pagination.to - 50;
        } else if (e === this.state.totalAtendimentos) {
            let diff = this.state.totalAtendimentos - ((this.pagination.total - 1) * 50);
            this.pagination.from = this.state.totalAtendimentos - diff;
            this.pagination.to = (((this.pagination.total - 1) * 50) - 1) + 50;
        }
        this.setState(this.state);
    }

    resetRange = () => {
        this.pagination.from = 0;
        this.pagination.to = 49;
        this.total = 0;
        this.ticketMedio = 0;
        this.setState(this.state);
    }

    getFilterResult = (filter, from, to, filterThis) => {
        this.lines = [];
        this.atendimentoList = [];
        this.filterThis = filterThis;
        this.setState(this.state);
        this.showLoading();
        API.get('/rest/gestao/Atendimento/countAtendimentos', { params: filter }).then(res => {
            this.setState({ totalAtendimentos: res.data.count });
            this.pagination.total = Math.ceil(res.data.count / 50);
            this.pagination.from = from;
            this.pagination.to = to;

            API.get('/rest/gestao/Atendimento/find', { params: filter }).then(res => {
                this.atendimentoList = res.data;
                this.setState({ emptyList: this.atendimentoList.length === 0 ? 'noResults' : '' });
                this.getLinesAtendimento();
                if (from === 0) {
                    API.get('rest/gestao/Atendimento/sum/numeroReceitaEstimada', { params: filter }).then(res => {
                        this.setState({ ticketMedio: res.data && res.data.sum && this.atendimentoList.length > 0 ? res.data.sum / this.state.totalAtendimentos : 0 });
                    }).catch(error => {
                        console.log(error);
                        this.setState({ ticketMedio: 0 });
                    });
                }
            }).catch(error => {
                this.closeLoading();
                this.showToast('Erro ao buscar os atendimentos. Por favor, entre em contato com o suporte.', 'error')
            });
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao listar os atendimentos. Por favor, entre em contato com o suporte.', 'error')
        });
    }

    refreshAtendimento = (atendimento) => {
        this.atendimento = atendimento;
        this.setState(this.state);
    }

    donwloadAnexosAtendimento = () => {
        this.showLoading();
        window.open(BASE_URL + 'rest/gestao/Atendimento/download/' + this.atendimento.id);
        this.closeLoading();
    }

    showDownloadAnexosButton = () => {
        return this.atendimento && ((this.atendimento.proposta && this.atendimento.proposta.anexoPropostaList && this.atendimento.proposta.anexoPropostaList.length > 0)
            || (this.atendimento.anexoIndicadorProdutividadeList && this.atendimento.anexoIndicadorProdutividadeList > 0) 
            || (this.atendimento.termoAceiteList && this.atendimento.termoAceiteList.length > 0)
            || (this.atendimento.anexoAtendimentoReferencialList && this.atendimento.anexoAtendimentoReferencialList.length > 0));
    }

    editarSolicitacaoStatus = (e) => {
        if (e != null) {
            this.atendimento.solicitacaoStatus = e;
            this.setState(this.state);
        }
    }

    render() {
        window.scrollTo(0, 0);
        return (
            <Page icon={'showcase.svg'} label={'Gerenciar Atendimentos'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                { !this.modalTrilha &&
                    <Grid container className={'gerenciar-atendimentos'}>
                        {this.atendimento != null && this.resumo ?
                            <Grid container spacing={3} className={'atendimento'}>
                                <Grid container className={'pagination-gerenciar-atendimento'}>
                                    <div
                                        onClick={() => {
                                            this.atendimento = null;
                                            this.resumo = false;
                                            this.setState(this.state);
                                        }}
                                    >
                                        <div className={'return-gerenciar-atendimento'}>
                                                <ArrowBackIosIcon style={{ color: '#077CDC', fontSize: '28px' }} />
                                                <p>Gerenciar atendimentos</p>
                                        </div>
                                    </div>
                                    <ArrowForwardIosIcon style={{ color: 'darkGrey', fontSize: '14px' }} />
                                    <p style={{ color: '#077CDC'}}> Resumo de atendimento</p>
                                </Grid>
                                <Resumo
                                    canChangeStatus={this.canChangeStatus}
                                    atendimento={this.atendimento}
                                    atendimentoBackNovo={this.atendimentoBackNovo}
                                    usuario={this.props.usuarioConectado}
                                    indicadores={this.indicadores}
                                    isDN={this.isDN}
                                    isDR={this.isDR}
                                    isUO={this.isUO}
                                    isAceito={this.isAceito}
                                    isNegociacao={this.isNegociacao}
                                    isPronto={this.isPronto}
                                    isExecucao={this.isExecucao}
                                    isConcluido={this.isConcluido}
                                    isArquivado={this.isArquivado}
                                    isCancelado={this.isCancelado}
                                    isAtendimentoBP={this.isAtendimentoBP}
                                    isAtendimentoIA={this.isAtendimentoIA}
                                    isAtendimentoIA180={this.isAtendimentoIA180}
                                    isAtendimentoIE={this.isAtendimentoIE}
                                    isAtendimentoIPEA={this.isAtendimentoIPEA}
                                    isAtendimentoLEAN={this.isAtendimentoLEAN}
                                    isAtendimentoLEAN28={this.isAtendimentoLEAN28}
                                    isAtendimentoLEAN56={this.isAtendimentoLEAN56}
                                    isAtendimentoMENTORIA={this.isAtendimentoMENTORIA}
                                    isAtendimentoDIGITAL={this.isAtendimentoDIGITAL}
                                    isAtendimentoBrasilMaisLEAN={this.isAtendimentoBrasilMaisLEAN}
                                    atendimentoJustificativa={this.atendimentoJustificativa}
                                    isMetrologia={this.isMetrologia}
                                    openAtendimento={this.openAtendimento}
                                    idProdutoBrasilProdutivo={this.idProdutoBrasilProdutivo}
                                    idProdutoBrasilProdutivoIPEA={this.idProdutoBrasilProdutivoIPEA}
                                    idProdutoBrasilProdutivoIE={this.idProdutoBrasilProdutivoIE}
                                    idProdutoBrasilProdutivoLEAN28={this.idProdutoBrasilProdutivoLEAN28}
                                    idProdutoBrasilProdutivoLEAN56={this.idProdutoBrasilProdutivoLEAN56}
                                    idProdutoBrasilProdutivoMENTORIA={this.idProdutoBrasilProdutivoMENTORIA}
                                    idConsultoriaManufatura180={this.idConsultoriaManufatura180}
                                    idProdutoIndustriaMaisAvancada={this.idProdutoIndustriaMaisAvancada}
                                    idMentoriaDigital={this.idMentoriaDigital}
                                    showButton={true}
                                    showToast={this.showToast}
                                    showLoading={this.showLoading}
                                    closeLoading={this.closeLoading}
                                    changeStatus={this.getStatus}
                                    formatData={this.formatData}
                                    changeModal={this.changeModal}
                                    changeModalShowToast={this.changeModalShowToast}
                                    atendimentoTurma={this.atendimentoTurma}
                                    refreshAtendimento={this.refreshAtendimento}
                                    openIndicadoresScreen={this.openIndicadoresScreen}
                                    clearAtendimento={this.clearAtendimento}
                                    gerenciarAtendimento={true}
                                    turma={this.turma}
                                    isModuloNacional={this.isModuloNacional}
                                    editarSolicitacaoStatus={this.editarSolicitacaoStatus}
                                    mentoresDados={this.mentoresDados} />
                            </Grid>
                            :
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} className={'pesquisa'}>
                                            <Grid container>
                                                {!this.isDN ?
                                                    <Grid item xs={12} sm={5} xl={6}>
                                                        <Link to={'/cad/atendimento/atendimento/novo'}>
                                                            <GenericButton color={'darkBlue'} label={'Cadastrar Novo Atendimento'} subClass={'basic-button'} />
                                                        </Link>
                                                        <GenericButton color={'darkBlue-outline'} label={'Registrar proposta'} subClass={'basic-button'}
                                                            click={() => this.changeModal('modalRegistrarProposta', true)} />
                                                    </Grid>
                                                    : null}
                                                <Grid item xs={12} sm={this.isDN ? 12 : 7} xl={this.isDN ? 12 : 6}>
                                                    <Grid container>
                                                        <Grid item xs={12} sm={this.isDN ? 8 : 12} xl={9}>
                                                            <FiltroAtendimento
                                                                ref={this.childFiltro}
                                                                usuarioConectado={this.props.usuarioConectado}
                                                                pagination={this.pagination}
                                                                resetRange={this.resetRange}
                                                                getFilterResult={this.getFilterResult}
                                                                modalFiltro={this.modalFiltro}
                                                                formatData={this.formatData}
                                                                changeModal={this.changeModal}
                                                                isDN={this.isDN}
                                                                isDR={this.isDR}
                                                                isUO={this.isUO}
                                                                filterThis={this.filterThis} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={this.isDN ? 4 : 12} xl={3}>
                                                            <div className={'total'}>
                                                                <label>Total de atendimentos: <b>{this.state.totalAtendimentos ? this.state.totalAtendimentos.toLocaleString('pt-BR') : 0}</b></label><br></br>
                                                                <label>Ticket Médio: <b>{this.state.ticketMedio ? this.state.ticketMedio.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0,00"}</b></label>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={this.isDN ? 'tabela-atendimento-DN' : this.isDR ? 'tabela-atendimento-DR' : 'tabela-atendimento-UO'}>
                                    <Table
                                        lines={this.lines}
                                        titles={this.titles}
                                        initialOrder={''}
                                        action={this.openAtendimento}
                                        actionParamer={'id'}
                                        pagination={false} />
                                    {this.lines.length > 0 ?
                                        <div className={'pagination'}>
                                            <p>{(this.pagination.from + 1).toLocaleString('pt-BR')}-{(this.pagination.from + this.lines.length).toLocaleString('pt-BR')} de {this.state.totalAtendimentos.toLocaleString('pt-BR')}</p>
                                            <IconButton onClick={() => this.changeRange(0)} disabled={this.pagination.from === 0}>
                                                <FirstPageIcon />
                                            </IconButton>
                                            <IconButton onClick={() => this.changeRange(-1)} disabled={this.pagination.from === 0}>
                                                <KeyboardArrowLeft />
                                            </IconButton>
                                            <IconButton onClick={() => this.changeRange(1)} disabled={!(this.pagination.total > 1 && (Math.ceil((this.pagination.to + 1) / 50) !== this.pagination.total))}>
                                                <KeyboardArrowRight />
                                            </IconButton>
                                            <IconButton onClick={() => this.changeRange(this.state.totalAtendimentos)} disabled={!(this.pagination.total > 1 && (Math.ceil((this.pagination.to + 1) / 50) !== this.pagination.total))}>
                                                <LastPageIcon />
                                            </IconButton>
                                        </div>
                                        : null}
                                    <EmptyList type={this.state.emptyList} seeAll={() => { this.childFiltro.current.search() }} />

                                </Grid>

                            </Grid>
                        }
                        {this.modalRegistrarProposta || this.modalVisualizarProposta ?
                            <Proposta
                                atendimento={this.atendimento}
                                openAtendimento={this.openAtendimento}
                                proposta={this.atendimento != null ? this.atendimento.proposta : null}
                                cliente={this.atendimento != null ? this.atendimento.cliente : null}
                                modalVisualizarProposta={this.modalVisualizarProposta}
                                modalRegistrarProposta={this.modalRegistrarProposta}
                                changeModal={this.changeModal}
                                isMetrologia={this.isMetrologia}
                                showToast={this.showToast}
                                showLoading={this.showLoading}
                                closeLoading={this.closeLoading}
                                changeModalShowToast={this.changeModalShowToast} />
                            : null}
                    </Grid>
                }

            </Page>
        );
    }
}